.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.menu-color { 
  color:#808080;
  font-family: Barlow;
  font-weight: 400;
  font-size:20px;
}

.login-color{
  color:#112B3C;
  font-family: Barlow;
  font-weight: 400;
  font-size:20px;
}



.menu-hover:hover, .menu-active {
  color: #112B3C;
  position: relative;
}

.menu-hover:hover::after, .menu-active::after {
  content: '';
  margin: 0 auto;
  margin-left: 25%;
  position: absolute;
  width: 50%;
  height: 3px;
  background: #E61C5D;
  border-radius: 50px;
  display: block;
  margin-top: 5px;
}

.PackagesSvg{
  height:50px;
  weight:50px;
  display: flex;
}


@media only screen and (max-width: 1024px) {
  .menu-hover:hover::after, .menu-active::after {
    color: #112B3C; 
     background-color: #E61C5D;
     border-radius: 100px;
     content: '';
     margin: 0 auto;
     width: 100%;
     display: block;
     padding: 2px;
     margin-top: 5px;  
  }
}

.navbar-button {
  background-color: #E61C5D;
  border-radius: 45px;
  margin-top: 10px;
  font-family: Barlow;
  font-size: 20px;
  font-weight: 500;
}

.navbar-hover:hover {
  background-color: #d36084;
  border-radius: 45px;
}

.FooterInfo{
  color: #000;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 180% */
  max-width: 388px;
}

.FooterHead{
  color:#112B3C;
  font-family: Barlow;
  font-size: 48px;
  font-weight: 700;
}

.FooterSecondHead{
  font-family: Barlow;
  font-weight: 700;
  font-size: 20px;
  margin-top: 10%;
  letter-spacing: 3px; 
}

.FooterMessageHeader{
  font-family: Barlow;
  font-weight: 600;
  font-size: 20px;
  /* margin-top: 80px; */
}

.hr1{
  border-top: 1px solid #FFC2D6;
}
.hr2{
  border-top: 1px solid #FDDD94;
  margin-top: 4px;
}
.hr3{
  border-top: 1px solid #E0E0E0;
  margin-top: 45px;
}
.SendBotton{
  background-color: #E61C5D;
  border-radius: 45px;
  margin-top: 10px;
  padding-left: 70px;
  padding-right: 70px;
  font-family: Inter;
}
.FooterContact{
  color: #000000;
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
}

.FooterContactText{
  color: #000;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
}
.FooterContcatlocationText{
  margin-left: 35px;
}
.FooterMenu{
  font-size: 20px;
  font-weight: 400;
  font-family: Barlow;
  text-align: center;
  color: #000000;
}
.FooterMenu:hover,
.FooterMenu.active
{
  color:#E61C5D;
  font-weight: 600;
}

.FooterMenu:hover,
.menu-active1{
  color:#E61C5D;
  font-weight: 600;
}

.FooterCopyright{
  font-family: Barlow;
  font-weight: 400;
  font-size: 18px;
  color: #4A4A4A;
}

.welcomeFont{
  color:#E61C5D;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3px; 
  /* margin-top: 30px; */

}
.welcomeHeader{
  color: var(--Black, #112B3C);
  font-family: Barlow;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 529px;
}
.welcomeHeaderPink{
  color:#E61C5D;
}
.welcomeHeaderYellow{
  text-decoration: underline  solid #FFF6A6;
  text-decoration-thickness: 8px;
  text-underline-offset: 10px;
}
.welcomeText{
  font-family: Barlow;
  font-weight: 400;
  font-size: 20px;
  color:#808080;
  margin-top: 30px;
  max-width: 534px;

}
.infoCardMargin{
  margin-left: 60px;
  margin-right: 60px;
}
.infoCardHeader{
  font-family: Barlow;
  font-weight: 600;
  font-size: 24px;
  color:#112B3C;
  max-width: 225px;

}
.infoCardText{
  margin-top: 20px;
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
  color:#616161;
  max-width: 195px;

}
.infocardbg{
  background-color: #F9F9F9;
  /* background-color: #f51111; */
  border-radius: 14%;
}



.offeringGreen{
background-color: #A7F2F3;
width: 59px;
height: 59px;
flex-shrink: 0;
}

.offeringYellow{
  background-color: #FFF0CF;
  width: 59px;
  height: 59px;
  flex-shrink: 0;
}
.offeringGray{
  background-color: #D8EEFF;
  width: 59px;
  height: 59px;
  flex-shrink: 0;
}
.offeringPink{
  background-color: #FFD1D8;
  width: 59px;
  height: 59px;
  flex-shrink: 0;
}

.countColor{
  color: #2C2B2B;
  font-family: Barlow;
  font-weight: 600;
  font-size: 30px;
}

.offeringHeader{
  /* margin-top: 10px; */
  color: #112B3C;
  font-family: Barlow;
  font-weight: 700;
  font-size: 48px;
  line-height: 70px;
  margin-bottom: 30px;
}

.photoGallaryHeader{
  color: var(--Black, #112B3C);
font-family: Barlow;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.offeringText{
  color: #2C2B2B;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 38.4px */
}

.offeringMargin{
  margin-left: 20px;
}

.packageHeader{
  font-family: Barlow;
  font-weight: 700;
  font-size: 36px;
  line-height: 43.2px;
  max-width: 608px;

}

.packageFooter{
  color: #1E1E1E;
  text-align: right;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.PackageContentFirst{
  font-family: Barlow;
  font-weight: 600;
  font-size: 16px;
  line-height: 25.6px;
  color: #000000;
  max-width: 503px;

}

.PackageContentSecond{
  font-family: Barlow;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #000000;
  max-width: 503px;

}

.PackageData{
  border-radius: 8.202px;
  border: 0.82px solid #A89A65;
  background: #FFFCF2;
  box-shadow: 0px 4px 0px 0px #A89A65;
  max-width: 187px;
  max-height: 190px;
  flex-shrink: 0;
}
.PackageCardText{
  color: #D4205A;
text-align: center;
font-family: Barlow;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
}

.serviceCard{
  border-radius: 36px;
}


.serviceCardHeader{
  font-family: Barlow;
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
  color: #000000;
  letter-spacing: 3px; 
}

.serviceCardFirstBg{
  background-color: #D8EEFF;
}

.serviceCardSecondBg{
  background-color: #FFF0CF;
}

.serviceCardThirdBg{
  background-color: #A7F2F3;
}

.serviceCardFirstContentFirst{
  color: #0D2B42;
  font-family: Barlow;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 516px;
}

.serviceCardFirstContent{
  color: #0D2B42;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; 
  max-width: 218px;
}
.serviceCardSecondHeader{
  color: #423210;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 220px;
}
.serviceCardSecondContent{
  color: #423210;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; 
  max-width: 229px;
}

.serviceCardThirdHeader{
  color: #114142;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 203px;
}
.serviceCardThirdContent{
  color: #114142;
  font-family: Barlow;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; 
  max-width: 224px;
}
.absolute{   
  position: absolute;
  right: 0;
}
.relative{      
  position: relative;
}
.serviceImgMargin{  
  margin-top: 170px;
}
.serviceCardImg1{    
  float: right; 
  display: flex; 
  align-items: flex-end; 
  max-width: auto;
}
.serviceCardImg2{  
  margin-top: 15px;
  float: right; 
  display: flex; 
  align-items: flex-end;  
  max-width: 100%;
} 

@media only screen and (max-width: 376px) {
  .PackageCardText{
    color: #D4205A;
    text-align: center;
    font-family: Barlow;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;  
  }
}

@media only screen and (max-width: 376px) {
  .offeringMargin{
    margin-left: 10px;
  }
}

.footerMessageDesign{
  border-radius: 8px;
  border: 1px solid #C9C9C9;
  background: #FBFBFB;
  max-width: 288px;
  max-height: 46px;
}

.footerMessageDesignTextarea{
  border-radius: 8px;
  border: 1px solid #C9C9C9;
  background: #FBFBFB;
  max-width: 288px;
  max-height: 116px;
}

.footerMessageDesign::placeholder,
.footerMessageDesignTextarea::placeholder {
  color: #484848;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.singlePersonText{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50.34px;
  padding-right: 60.34px;
  flex-shrink: 0;
  border-radius: 60px 0px 0 60px;
  background: #B74E3B;
  color: #FFF;
  font-family: Barlow;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.couplePackageText{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50.34px;
  padding-right: 140.34px;
  flex-shrink: 0;
  border-radius: 60px 0px 0 60px;
  background: #CB1767;
  color: #FFF;
  font-family: Barlow;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.threeMonthPackageText{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50.34px;
  padding-right: 90.34px;
  flex-shrink: 0;
  border-radius: 60px 0px 0 60px;
  background: #CB1767;
  color: #FFF;
  font-family: Barlow;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.offeringGreenSinglePer{
background-color: #A7F2F3;
width: 65px;
height: 65px;
flex-shrink: 0;
}

.offeringYellowSinglePer{
  background-color: #FFF0CF;
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  }

  
.offeringBlueSinglePer{
  background-color: #D8EEFF;
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  }

  .offeringPinkSinglePer{
    background-color: #FFD1D8;
    width: 65px;
    height: 65px;
    flex-shrink: 0;
    }

.offeringTextSinglePer{
  color: #2C2B2B;
font-family: Barlow;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 160%; 
max-width:  501.569px;
}

.contactUsSinglePer{
  max-width: 347.511px;
  max-height: 68.774px;
  flex-shrink: 0;
  border-radius: 60px;
  background: #E61C5D;
  color: #FFF;
  font-family: Barlow;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  align-items: flex-end;
}

.singlePageBg1{
  border-radius: 36px 36px 36px 36px;
  background: #FFE6DD;
}
.singlePageBg2{
  border-radius: 36px 36px 36px 36px;
  background: #E8F4FF;
}
.singlePageBg3{
  border-radius: 36px 36px 36px 36px;
  background: #EBF4D7;
}
.singlePagetext{
  color: var(--Black, #112B3C);
  text-align: center;
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px; 
}

.sendButton{
  max-width: 181px;
  max-height: 44px;
  flex-shrink: 0;
  border-radius: 38px;
  background: #E61C5D;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.10);
}

.singleContactForm{
  max-width: 650px;
  height: 800px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #8C816A;
  background: #FEFAF2;
  box-shadow: 0px 15px 24px 0px rgba(0, 0, 0, 0.10);
}

.contactForm{
  max-width: 650px;
  height: 550px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #8C816A;
  background: #FEFAF2;
  box-shadow: 0px 15px 24px 0px rgba(0, 0, 0, 0.10);
}

.HeaderForm{
  color: #725926;
  font-family: Barlow;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.inputDouble{
  max-width: 292px;
  max-height: 46px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #D3C4A5;
  background: #FFF;
}
.inputName{
  display: flex;
  max-height: 46px;
  padding: 10px 16px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #D3C4A5;
  background: #FFF;
}
.inputSingle{
  display: flex;
max-height: 46px;
padding: 10px 16px;
align-items: flex-start;
gap: 24px;
align-self: stretch;
border-radius: 8px;
border: 1px solid #D3C4A5;
background: #FFF;
}
.inputMessage{
  display: flex;
  max-width: 608px;
  max-height: 166px;
  padding: 24px 32px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #D3C4A5;
  background: #FFF;
}

input.inputDouble::placeholder, 
input.inputSingle::placeholder,
textarea.inputMessage::placeholder, 
select.inputDouble::placeholder,
input.inputName::placeholder,
select.inputName::placeholder
 {
  color: #484848;
font-family: Barlow;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

select.inputDouble,
select.inputName{
  color: #484848;
font-family: Barlow;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.formFooter{
  color: #5F5F5F;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-container {
  position: relative;
  display: inline-block;
}

input {
  background-color: transparent;
  position: relative;
  padding: 5px;
  z-index: 1;
  border: none;
}

textarea{
  background-color: transparent;
  position: relative;
  padding: 5px;
  z-index: 1;
  border: none;
}

.placeholder {
  position: absolute;
  color: #000000;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #484848;
  font-family: Barlow;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
}

.textareaPlaceholder{
  top: 20%;
  left: 10px;

}

input:required + .placeholder::after {
  content: " *";
  color:#E61C5D;
}
 
input:focus + .placeholder {
  /* visibility: hidden; */
  opacity: 0;
}
input:valid + .placeholder{
  visibility: hidden;
  /* opacity: 0; */
}

textarea:required + .placeholder::after {
  content: " *";
  color: red;
}
 
textarea:focus + .placeholder {
  /* visibility: hidden; */
  opacity: 0;
}
textarea:valid + .placeholder{
  visibility: hidden;
  /* opacity: 0; */
}

.photoGallery{
color: #FFF;
font-family: Barlow;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.SinglePicSize{
  width: 700px;
  height:600px;
  flex-shrink: 0;
  border-radius: 16px;
/* background:lightgray 50% / cover no-repeat; */
}
.photoGalleryMobile img {
    cursor: pointer;
}
    
button {
     cursor: pointer;
}

.ArrowImageSize{
  height: 40px;
  width: 40px;
}

.ArrowImageSizeTumbnail{
  height: 20px;
  width: 20px;
}
.QuickLinks{
  color: #000;
  font-family: Barlow;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
}

.cancleButton{
  height: 40px;
  width:40px
}

.cancleButtonMobile{
  height:20px;
  width:20px
}

@media only screen and (max-width: 600px) {
  .bannerPic{
    width: 100%;
    height: 204.947px;  
  }
  .bannerHeaderFont{
    margin-top: 50px;
    color: #FFF;
    text-align: center;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .bannerHeaderContent{
    margin-top: 1.84px;
    color: #FFF;
    text-align: center;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .bannerButton{
    display: inline-flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 11.109px;
    background: var(--Primary, #E61C5D);
    color: var(--White, #FFF);
    font-family: Barlow;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .welcomeImage{
    margin-top: 20px;
    width: 50%;
    height: 50%;
    margin-left: 96.06px;
    flex-shrink: 0;
  }
  .welcomeHeaderMobile{
    margin-left: 26px;
    color: var(--Primary, #E61C5D);
    font-family: Barlow;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .welcomeHeaderContentMobile{
    margin-left: 26px;
    color: var(--Black, #112B3C);
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 310.367px;  
  }
  .welcomeHeaderContentPinkMobile{
    color: #E61C5D;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .welcomHeaderYellowMobile{
    text-decoration: underline  solid #FFF6A6;
    text-decoration-thickness: 3.582px;
    text-underline-offset: 5px;
  }
  .welcomeTextMobile{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-bottom: 50px;
    margin-left: 26px;
    color: var(--Grey-1, #808080);
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    max-width: 308.068px;
  }
  .InfocardTitleMobile{
    color: #112B3C;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 200px;
    /* margin-left: 40px;
    margin-right: 46px; */
    /* margin-left: 26px;
    color: var(--Black, #112B3C);
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 310.367px;   */
  }
  /* .infocardMargin{
    margin-top: 20px;
    margin-right: 0%;
  } */
  .infocardTextMobile{
    color: #616161;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    max-width: 195px;
  }
  .serviceCardHeaderMobile{
    color: #0D2B42;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
  }
  .serviceCardFirstContentFirstMobile{
    color: #0D2B42;
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 246.907px;
  }
  .serviceCardFirstContentMobile{
    color: #0D2B42;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    max-width: 247px;
  }
  .serviceCardSecondHeaderMobile{
    color: #423210;
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 248px;
  }
  .serviceCardSecondContentMobile{
    color: #423210;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    max-width: 248px;
    margin-bottom: 38px;
  }
  .serviceCardThirdHeaderMobile{
    color: #114142;
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 248px;
  }
  .serviceCardThirdContentMobile{
    color: #114142;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 28.8px */
    max-width: 248px;
  }
  .packageWelcomeFontMobile{
    color: var(--Primary, #E61C5D);
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
  }
  .packageHeaderMobile{
    color: var(--Black, #112B3C);
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 248px;
    margin-top: 16px;
  }
  .packageCardNone{
    display: none;
  }
  .packageBorderRediusMobile{
    border-radius: 36px;
    /* max-width: 100%; */
    height: auto;
    margin:24px;
    display: block;
    background: #FDF8E4;
  }
  .packageContentFirstMobile{
    color: #000;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 25.6px */
    max-width: 248px;
  }
  .packageContentSecondMobile{
    color: #000;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    max-width: 248px;
  }
  .packageContentThirdMobile{
    color: #1E1E1E;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 248px;
    margin-bottom: 20px;
  }
  .PackageDataMobile{
    max-width: 248px;
    max-height: 190px;
    border-radius: 8.202px;
    border: 0.82px solid #A89A65;
    background: #FFFCF2;
    box-shadow: 0px 4px 0px 0px #A89A65;
  }
  .PackageCardTextMobile{
    color: #D4205A;
    text-align: center;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    max-width: 139px;
    margin-right: 54.5px;
    margin-left: 54.5px;
  }
  .offeringFontMobile{
    margin-left: 28px;
    color: var(--Primary, #E61C5D);
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
  }
  .offeringHeaderMobile{
    margin-left: 28px;
    color: var(--Black, #112B3C);
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 248px;
  }
  .CouplePackageOfferingHeaderMobile{
    margin-left: 28px;
    color: var(--Black, #112B3C);
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 308px;
  }
  .offeringImageMobile{
    display: none;
  }
  .offeringImageSize{
   width: auto;
   height: auto;
   margin-left: 20px;
   margin-bottom: 20px;
  }
  .offeringMarginMobile{
    margin-left: 27px;
  }
  .offeringTextMobile{
    color: #2C2B2B;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; 
    max-width: 229.344px;
  }
  .welcomeHeaderYellowMobile{
    text-decoration: underline  solid #FFF6A6;
    text-decoration-thickness: 5px;
    text-underline-offset: 5px;
  }
  .footerMarginMobile{
    margin-top: 64.46px;
  }
  .FooterHeadMobile{
    color: var(--Black, #112B3C);
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .FooterSecondHeadMobile{
    color: var(--Primary, #E61C5D);
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 4px;
    text-transform: uppercase;
  }
  .FooterInfoMobile{
    color: #000;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 312.793px;
  }
  .footerIconMobile{
    display: none;
  }
  .copyFooterMargin{
    margin-bottom: 50px;
  }
  .footerMessageDesignMobile{
    border-radius: 8px;
    border: 1px solid #C9C9C9;
    background: #FBFBFB;
    max-width: 288px;
    max-height: 46px;
    flex-shrink: 0;
  }
  .footerMessageDesignTextareaMobile{
    border-radius: 8px;
    border: 1px solid #C9C9C9;
    background: #FBFBFB;
    width: 288px;
    height: 116px;
    flex-shrink: 0;
  }
  .SendBottonMobile{
    border-radius: 38px;
    background: #E61C5D;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.10);
    max-width: 181px;
    max-height: 44px;
    flex-shrink: 0;
  }
  .footerGetinTouch{
    margin-top: 10px;
    color: #000;
    font-family: Barlow;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; 
    max-width: 168px;;
  }
  .FooterContactTextMobile{
    color: #000;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
  }
  .FooterForMobile{
    display:none;
  }
  .footerMenuHiddenMobile{
    display: none;
  }
  .FooterCopyrightMobile{
    margin-left: 20px;
    margin-bottom: 31px;
    color: #4A4A4A;
    font-family: Barlow;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    max-width: 311.959px;
  }

  .singlePersonTextMobile{
    margin-bottom: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20.34px;
    padding-right: 30.34px;
    flex-shrink: 0;
    border-radius: 60px 0px 0 60px;
    background: #B74E3B;
    color: #FFF;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .couplePackageTextMobile{
    margin-bottom: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20.34px;
    padding-right: 30.34px;
    flex-shrink: 0;
    border-radius: 60px 0px 0 60px;
    background: #CB1767;
    color: #FFF;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .threeMonthPackageTextMobile{
    margin-bottom: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20.34px;
    padding-right: 30.34px;
    flex-shrink: 0;
    border-radius: 60px 0px 0 60px;
    background: #CB1767;
    color: #FFF;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
 
  .contactUsSinglePerMobile{
    max-width: 230px;
    max-height: 42px;
    flex-shrink: 0;
    border-radius: 60px;
    background: #E61C5D;
    color: #FFF;
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-flex;
    align-items: flex-end;
  }
  .TourpackagesMarginMobile{
    margin-bottom: 20px;
  }
  .coupleImgMobile{
    margin-left: 30px;
    margin-right: 20px;
    max-width:312px;
    max-height: 244.781px;
    flex-shrink: 0;  
  }
  .singlePerImgMobile{
    margin-left: 30px;
    margin-right: 20px;
    max-width: 233.762px;
    max-height: 153.367px;
    flex-shrink: 0;
  }
  .mobileForm{
   max-width: 315.762px;
    max-height: 966.72px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .ContactFormmobile{
    max-width: 315.762px;
     max-height: 850.72px;
     flex-shrink: 0;
     justify-content: center;
     align-items: center;
     padding: 10px;
   }

  .mobileFormHeader{
    color: #725926;
    font-family: Barlow;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .formFooterMarginMobile{
    padding-bottom: 50px;
  }

  .serviceMarginMobile{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .photoGallaryHeaderMobile{
    color: var(--Black, #112B3C);
    font-family: Barlow;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .photoGalleryMobile{
    margin:10px 20px 10px 20px
  }
  .SinglePicSizeMobile{
    padding: 10px;
    width: 500px;
    height:300px;
    flex-shrink: 0;
    border-radius: 16px;
  /* background:lightgray 50% / cover no-repeat; */
  }

}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .bannerPicTablate{
    width: 100%;
    height: 400.947px;  
  }
  .welcomeImageTablate{
    width: 70%;
    height: 70%;
  }
  .offeringImageTablate{
   height: 700px;
    max-width:700px;
  }
  /* .tourPackageTablate{
    width: 100%;
    height: 400.947px; 
  } */
   .singlePersonImageTablate{
    height: 700px;
    max-width:700px;
   }

   .coupleImageTablate{
    height: 700px;
    max-width:700px;
   }
   

   .singlePersonTextTablate{
    margin-bottom: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20.34px;
    padding-right: 30.34px;
    flex-shrink: 0;
    border-radius: 60px 0px 0 60px;
    background: #B74E3B;
    color: #FFF;
    font-family: Barlow;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .couplePackageTextTablate{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50.34px;
    padding-right: 140.34px;
    margin-bottom: 40px;
    flex-shrink: 0;
    border-radius: 60px 0px 0 60px;
    background: #CB1767;
    color: #FFF;
    font-family: Barlow;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .threeMonthPackageTextTablate{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 50.34px;
    padding-right: 140.34px;
    margin-bottom: 40px;
    flex-shrink: 0;
    border-radius: 60px 0px 0 60px;
    background: #CB1767;
    color: #FFF;
    font-family: Barlow;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }




}


